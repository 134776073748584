import { css } from "@emotion/core";

export default css`
  @import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap");

  body {
    margin-right: calc(-1 * (100vw - 100%));
    font-family: "Rubik", "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 0 !important;
    overflow-x: hidden;
  }

  a {
    cursor: pointer;
    outline: none;
  }

  a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  svg {
    fill: currentColor;
  }

  input {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
