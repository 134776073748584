import { CssBaseline, StylesProvider } from "@material-ui/core";
import MuiThemeProvider from "@material-ui/styles/ThemeProvider";
import { ThemeProvider } from "emotion-theming";
import * as React from "react";

import muiTheme, { theme } from "~/theme/theme";

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

const AppStylesProvider: React.FC<Props> = ({
  children
}: Props): JSX.Element => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export default AppStylesProvider;
