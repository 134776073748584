import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import zIndex from "@material-ui/core/styles/zIndex";

import { default as Theme, Overrides } from "~/declarations/theme";
import { hexToRgba } from "~/utils/style";

const RubikFontFamily = "Rubik";
const HeeboFontFamily = "Heebo";
const FallbackFontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';

export const theme: Theme = {
  breakpoints: createBreakpoints({ unit: "px" }),
  typography: {
    fontFamily: `${RubikFontFamily}, ${FallbackFontFamily}`,
    h4: {
      fontFamily: `${HeeboFontFamily}, ${FallbackFontFamily}`
    }
  },
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff"
    },
    primary: {
      dark: "#39486f",
      main: "#067BC2",
      light: "#65add9",
      lighter: "#cde5f3"
    },
    secondary: {
      main: "#FED766",
      light: "#7ea2fc"
    },
    error: {
      main: "#FF0000",
      light: "#f45b69"
    },
    success: {
      main: "#4caf50"
    },
    grey: {
      900: "#424242",
      800: "#666666",
      600: "#9e9e9e",
      400: "#dbdbdd",
      200: "#f3f3f3",
      50: "#fafafc"
    },
    data: {
      blue: {
        100: "#c1d8ff",
        300: "#9bcae7",
        500: "#6ab0da",
        700: "#3895ce",
        900: "#067bc2"
      },
      blueGrey: "#fafbff",
      indigo: "#6b6ecf",
      turquoise: {
        100: "#def3f3",
        300: "#bde7e7",
        500: "#9bdbdb",
        600: "#7acfcf",
        900: "#59c3c3"
      }
    }
  },
  zIndex,
  layouts: {
    account: {
      content: {
        borderRadius: 20,
        padding: 30
      },
      sidebarWidth: {
        min: 70,
        max: 268
      },
      filterToolbar: {
        height: 50
      }
    }
  }
};

const overrides: Overrides = {
  ...(typeof theme?.overrides === "object" ? theme?.overrides : {}),
  MuiPaper: {
    rounded: {
      borderRadius: "24px"
    }
  },
  MuiDialog: {
    paper: {
      borderRadius: "4px"
    }
  },
  MuiInput: {
    root: {
      "&$disabled": {
        color: theme.palette.grey["800"]
      }
    },
    underline: {
      "&:before": {
        borderBottom: `1px solid ${theme.palette.grey["400"]}`
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 25,
      "& > fieldset": {
        borderColor: theme.palette.grey["400"]
      }
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: "12px",
      textTransform: "uppercase"
    }
  },
  MuiTableCell: {
    root: {
      borderBottom: "unset"
    },
    head: {
      color: theme.palette.grey["600"],
      fontSize: "10px",
      textTransform: "uppercase",
      border: `1px solid ${theme.palette.grey["400"]}`,
      borderWidth: "1px 0 1px 0"
    }
  },
  MuiSelect: {
    root: {
      textTransform: "uppercase",
      marginRight: "15px"
    }
  },
  MuiPopover: {
    paper: {
      borderRadius: "5px"
    }
  },
  MuiAutocomplete: {
    root: {
      textTransform: "uppercase"
    },
    popper: {
      marginTop: "4px"
    },
    paper: {
      borderRadius: "4px",
      textTransform: "uppercase",
      boxShadow: `0px 10px 10px ${hexToRgba(theme.palette.common.black, 0.4)}`
    },
    inputRoot: {
      "& input": {
        textTransform: "uppercase",
        fontSize: "13px"
      },
      "&.MuiOutlinedInput-root": {
        padding: "2px 9px"
      }
    },
    listbox: {
      padding: 0
    },
    option: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    }
  }
};

export default createMuiTheme({ ...theme, overrides });
