import { Snackbar, SnackbarOrigin } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import * as React from "react";
import { A } from "ts-toolbelt";

type SnackData = {
  severity: AlertProps["severity"];
  message: string;
  visible: boolean;
  valign?: SnackbarOrigin["vertical"];
};

type ShowSnack = A.Compute<
  {
    [K in NonNullable<SnackData["severity"]>]: (
      message: SnackData["message"],
      valign?: SnackbarOrigin["vertical"]
    ) => void;
  }
>;

function makeProxy(fn: (data: Omit<SnackData, "visible">) => void): ShowSnack {
  return {
    info: (
      message: SnackData["message"],
      valign?: SnackbarOrigin["vertical"]
    ) => fn({ message, severity: "info", valign }),
    success: (
      message: SnackData["message"],
      valign?: SnackbarOrigin["vertical"]
    ) => fn({ message, severity: "success", valign }),
    error: (
      message: SnackData["message"],
      valign?: SnackbarOrigin["vertical"]
    ) => fn({ message, severity: "error", valign }),
    warning: (
      message: SnackData["message"],
      valign?: SnackbarOrigin["vertical"]
    ) => fn({ message, severity: "warning", valign })
  };
}

const SnackContext = React.createContext<ShowSnack>(makeProxy(() => undefined));

export function useShowSnack(): ShowSnack {
  return React.useContext(SnackContext);
}

export const SnackLayout: React.FC = ({ children }) => {
  const [snackData, setSnackData] = React.useState<SnackData>({
    severity: "success",
    message: "",
    visible: false
  });

  const closeSnackbar = React.useCallback(() => {
    setSnackData(data => ({
      ...data,
      visible: false
    }));
  }, []);

  const showSnackbar = React.useMemo(
    () =>
      makeProxy((data: Omit<SnackData, "visible">) =>
        setSnackData({
          ...data,
          visible: true
        })
      ),
    []
  );

  return (
    <>
      <SnackContext.Provider value={showSnackbar}>
        {children}
      </SnackContext.Provider>
      <Snackbar
        open={snackData.visible}
        autoHideDuration={6_000}
        onClose={closeSnackbar}
        anchorOrigin={{
          horizontal: "center",
          vertical: snackData.valign ?? "top"
        }}
      >
        <Alert onClose={closeSnackbar} severity={snackData.severity}>
          {snackData.message}
        </Alert>
      </Snackbar>
    </>
  );
};
